import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { User } from '@xpo-ltl/sdk-common';
import { get as _get } from 'lodash';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';

@Injectable({ providedIn: 'root' })
export class UserRoleService {
  private user: User;
  private _isProduction = false;
  private readonly removeDomainRegExp = /.*\//gm;

  constructor(private configManagerService: ConfigManagerService) {
    this._isProduction = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
  }

  setUser(user: User): void {
    this.user = user;
  }

  getUser(): User {
    return this.user;
  }

  getUserId(): string {
    return _get(this.user, 'userId');
  }

  getEmployeeId(): string {
    return _get(this.user, 'employeeId');
  }

  isProduction(): boolean {
    return this._isProduction;
  }

  // isApprover(): boolean {
  //   const approverRoles =
  //     this.hasRole(this.user.roles, 'LTL_PND_DR_OCR_APPROVER') ||
  //     this.hasRole(this.user.roles, 'TST_LTL_PND_DR_OCR_APPROVER');
  //   return approverRoles;
  // }

  isAuthorizedUser(user: User): boolean {
    // const approvedProdRoles: boolean =
    //   this.hasRole(user.roles, 'LTL_PND_DR_OCR_APPROVER') || this.hasRole(user.roles, 'LTL_PND_DR_OCR_REVIEWER');

    // const approvedTestRoles: boolean =
    //   this.hasRole(user.roles, 'TST_LTL_PND_DR_OCR_APPROVER') ||
    //   this.hasRole(user.roles, 'TST_LTL_PND_DR_OCR_REVIEWER');

    // return this.isProduction() ? approvedProdRoles : approvedTestRoles;
    return true;
  }

  private hasRole(roles: string[], forRole: string): boolean {
    const roleToSearchFor = `/${forRole?.toUpperCase()}`;
    const foundRoles = roles?.find((role) => role?.toUpperCase()?.indexOf(roleToSearchFor) >= 0);
    return foundRoles?.length > 0;
  }
}
