import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { RoleSwitchGuard } from 'core/guards';

import { environment } from '../environments/environment';

// Skip SSO when running in Cypress test environment
const skipCanActivate = environment.cypress;

const RouterDefinitions: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: skipCanActivate ? [] : [XpoAuthenticationGuard, RoleSwitchGuard],
    loadChildren: () => import('./cartage-mgmt/cartage-mgmt.module').then((m) => m.CartageMgmtModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(RouterDefinitions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
