import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { XpoAllowCharactersModule, XpoLtlDialogTitleModule, XpoLtlPipeModule } from '@xpo-ltl/ngx-ltl';
import { XpoCellRenderersModule, XpoDashbarModule, XpoFiltersModule } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import {
  XpoButtonModule,
  XpoCheckboxModule,
  XpoDialogModule,
  XpoIconModule,
  XpoInlineSearchModule,
  XpoPopoverModule,
  XpoSelectModule,
  XpoStatusIndicatorModule,
  XpoTabsModule,
} from '@xpo-ltl/ngx-ltl-core';
import {
  XpoLtlOperationsCustomerProfileComponent,
  XpoLtlOperationsCustomerProfileModule,
} from '@xpo-ltl/ngx-ltl-cust-profile';
import { XpoAgGridModule } from '@xpo-ltl/ngx-ltl-grid';
import { XpoLtlShipmentDetailModule } from '@xpo-ltl/ngx-ltl-shipment-details';
import { AgGridModule } from 'ag-grid-angular';
import { DragScrollModule } from 'cdk-drag-scroll';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AppKeyboardShortcutsModule } from 'shared/keyboard-shortcuts/keyboard-shortcuts.module';
import { ActionsCellRendererComponent } from './components/actions-cell-renderer';

const pipes = [];

const directives = [];

// ADD ALL GRID RENDERERS TO THIS ARRAY SO THEY ARE AUTOMATICALLY ADDED TO
// DECLARATIONS, EXPORTS, AND ENTRY COMPONENTS ARRAYS!
const gridComponentRenderers = [ActionsCellRendererComponent];

@NgModule({
  imports: [
    AgGridModule,
    AppKeyboardShortcutsModule,
    ClipboardModule,
    CommonModule,
    DragDropModule,
    DragScrollModule,
    FormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDividerModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTooltipModule,
    NgxSkeletonLoaderModule,
    PdfViewerModule,
    ReactiveFormsModule,
    ScrollingModule,
    XpoAgGridBoardModule,
    XpoAgGridModule,
    XpoAllowCharactersModule,
    XpoButtonModule,
    XpoCellRenderersModule,
    XpoCheckboxModule,
    XpoDashbarModule,
    XpoDialogModule,
    XpoFiltersModule,
    XpoIconModule,
    XpoInlineSearchModule,
    XpoLtlDialogTitleModule,
    XpoLtlOperationsCustomerProfileModule,
    XpoLtlPipeModule,
    XpoLtlShipmentDetailModule,
    XpoPopoverModule,
    XpoSelectModule,
    XpoStatusIndicatorModule,
    XpoTabsModule,
  ],
  declarations: [...pipes, ...directives, ...gridComponentRenderers],
  exports: [XpoLtlOperationsCustomerProfileComponent, ...pipes, ...directives, ...gridComponentRenderers],
  entryComponents: [XpoLtlOperationsCustomerProfileComponent, ...gridComponentRenderers],
  providers: [...pipes, DatePipe, DecimalPipe],
})
export class CartageMgmtSharedModule {}
