import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Provider } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessaging, AngularFireMessagingModule } from '@angular/fire/messaging';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { SdkCustomerModule as SdkCustomerV2Module } from '@xpo-ltl-2.0/sdk-customer';
import { SdkLocationModule } from '@xpo-ltl-2.0/sdk-location';
import { SdkShipmentModule } from '@xpo-ltl-2.0/sdk-shipment';
import { CorrelationIdHttpInterceptor } from '@xpo-ltl/data-api';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthenticationService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import {
  XpoLtlDateSelectorModule,
  XpoLtlDmsUtilsModule,
  XpoLtlDocTypePipe,
  XpoLtlEnvironmentAndRoleGuardModule,
  XpoLtlLoggedInUserService,
  XpoLtlReleaseNotesModule,
  XpoLtlRoleSwitcherModule,
} from '@xpo-ltl/ngx-ltl';
import {
  XpoAccountPopoverModule,
  XpoBrowserGuardModule,
  XpoCommonModule,
  XpoDialogModule,
  XpoFeedbackModule,
  XpoIconModule,
  XpoMessagingPopoverModule,
  XpoPageNotFoundRoutingModule,
  XpoSnackBarModule,
} from '@xpo-ltl/ngx-ltl-core';
import { SdkAccountsReceivableModule } from '@xpo-ltl/sdk-accountsreceivable';
import { SdkAppMetadataModule } from '@xpo-ltl/sdk-appmetadata';
import { SdkBillOfLadingModule } from '@xpo-ltl/sdk-billoflading';
import { SdkCarrierManagementModule } from '@xpo-ltl/sdk-carriermanagement';
import { SdkCityOperationsModule } from '@xpo-ltl/sdk-cityoperations';
import { User } from '@xpo-ltl/sdk-common';
import { SdkCustomerModule } from '@xpo-ltl/sdk-customer';
import { SdkExceptionManagementModule } from '@xpo-ltl/sdk-exceptionmanagement';
import { SdkHumanResourceModule } from '@xpo-ltl/sdk-humanresource';
import { SdkInfrastructureModule } from '@xpo-ltl/sdk-infrastructure';
import { SdkLoggingModule } from '@xpo-ltl/sdk-logging';
import { SdkPickupRequestModule } from '@xpo-ltl/sdk-pickuprequest';
import { SdkPricingModule } from '@xpo-ltl/sdk-pricing';
import { SdkShipmentOdsModule } from '@xpo-ltl/sdk-shipmentods';
import { SdkUserPreferenceModule } from '@xpo-ltl/sdk-userpreference';
import { XPO_AUTH_CONFIG, XpoAuthConfig, XpoAuthenticationService, XpoAuthEventHandler } from '@xpo/ngx-auth';
import { XrtFireMessagingService } from '@xpo/ngx-xrt-firebase';
import { AngularSplitModule } from 'angular-split';
import { AppAuthEventHandler } from 'core/app-auth-event-handler.class';
import * as $ from 'jquery';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { UserManager } from 'oidc-client';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CoreModule, NotAuthorizedComponent } from '../core';
import { environment } from './../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorDialogComponent } from './cartage-mgmt/shared/components/error-dialog/error-dialog.component';
import { CartageMgmtSharedModule } from './cartage-mgmt/shared/shared.module';

window['$'] = $; // Needed for Jira issue tracking

/**
 * Auth service that always says the user is logged in.
 */
class XpoAuthenticationServiceAlwaysLoggedIn extends XpoAuthenticationService {
  constructor(manager: UserManager, router: Router, authEventListener: XpoAuthEventHandler) {
    super(manager, router, authEventListener);

    // ensure we are "logged in"
    manager.getUser().then((user) => {
      this.setUser(user);
    });
  }

  isLoggedIn() {
    // ALWAYS logged in for Cypress tests since Cypress did the login for us
    return true;
  }
}

/**
 * Auth service that prevents logout
 */
class XpoLtlAuthenticationServiceNoLogout extends XpoLtlAuthenticationService {
  logout() {
    console.log('CYPRESS TESTING - Disable Logout');
  }
}

/**
 * LoggedInUser service that returns a mocked user
 */
class XpoLtlLoggedInUserServiceAlwaysLoggedIn extends XpoLtlLoggedInUserService {
  getLoggedInUser(loggedInUserEndpoint: string): Observable<User> {
    const mockedUser = {
      userId: 'cmuser',
      employeeId: 'MOCK',
      displayName: 'User,  Mock',
      givenName: 'Mock',
      lastName: 'User',
      title: 'Senior Mock,  Cypress Testing',
      emailAddress: 'Mock.User@xpo.com',
      roles: ['CONWAY/DEFAULT_TABIGATOR', 'CONWAY/Conway_Tab_Default', 'Internal/everyone'],
      sicCode: 'IFR',
    } as User;

    return of(mockedUser).pipe(
      tap(() => {
        this['userLoggedInSubject'].next();
      })
    );
  }
}

// define the providers here so we can dynamically modify the list
const providers: Provider[] = [
  // { provide: DocumentManagementApiService, useClass: MockDocumentManagementApiService }, // TODO - this mocks the Document access since we don't have any in DMS right now
  { provide: HTTP_INTERCEPTORS, useClass: CorrelationIdHttpInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: UserIdHttpInterceptor, multi: true },
  {
    provide: XrtFireMessagingService,
    useFactory: (afm: AngularFireMessaging) => {
      return new XrtFireMessagingService(afm);
    },
    deps: [AngularFireMessaging],
  },
  {
    provide: XpoAuthEventHandler,
    useClass: AppAuthEventHandler,
  },
];

if (!!window['Cypress']) {
  // If we are running in a Cypress window, then always assume the user is Logged In, since Cypress tests
  // will set the correct tokens in the sessionStorage. This should still be secure since we still need
  // a valid token to make API calls.  All this will do is prevent the authentication system from trying
  // to automatically log in or fetch one. If we have a bad token, then the API calls will fail.
  // providers.push({ provide: UserManager, useClass: UserManagerCypress });
  providers.push({
    provide: XpoAuthenticationService,
    useClass: XpoAuthenticationServiceAlwaysLoggedIn,
    deps: [UserManager, Router, XpoAuthEventHandler],
  });
  providers.push({ provide: XpoLtlAuthenticationService, useClass: XpoLtlAuthenticationServiceNoLogout });
  providers.push({ provide: XpoLtlLoggedInUserService, useClass: XpoLtlLoggedInUserServiceAlwaysLoggedIn });
}

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

const materialModules = [
  MatButtonModule,
  MatDatepickerModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
];

const xpoLtlComponentModules = [
  XpoCommonModule,
  XpoDialogModule,
  XpoLtlDateSelectorModule,
  XpoSnackBarModule,
  XpoLtlDmsUtilsModule,
  XpoLtlEnvironmentAndRoleGuardModule,
  XpoBrowserGuardModule,
  XpoFeedbackModule,
  XpoAccountPopoverModule,
  XpoLtlReleaseNotesModule,
  XpoLtlRoleSwitcherModule,
  XpoMessagingPopoverModule,
  XpoIconModule,
];

@NgModule({
  declarations: [AppComponent, NotAuthorizedComponent, ErrorDialogComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ...materialModules,
    ...xpoLtlComponentModules,
    CoreModule,
    SdkAccountsReceivableModule,
    SdkAppMetadataModule,
    SdkBillOfLadingModule,
    SdkCarrierManagementModule,
    SdkCityOperationsModule,
    SdkCustomerModule,
    SdkCustomerV2Module,
    SdkExceptionManagementModule,
    SdkHumanResourceModule,
    SdkInfrastructureModule,
    SdkLocationModule,
    SdkLoggingModule,
    SdkPickupRequestModule,
    SdkPricingModule,
    SdkShipmentModule,
    SdkShipmentOdsModule,
    SdkUserPreferenceModule,
    SdkCarrierManagementModule,
    KeyboardShortcutsModule.forRoot(),
    AngularSplitModule.forRoot(),
    CartageMgmtSharedModule,
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    // XpoMaintenanceModule.initialize(<MaintenanceConfig>{ appName: SupportedAppEnum.PND }),
    AppRoutingModule,
    XpoPageNotFoundRoutingModule,
  ],
  entryComponents: [NotAuthorizedComponent],
  providers: [...providers, XpoLtlDocTypePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
