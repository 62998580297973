import { ChangeDetectionStrategy, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { Unsubscriber, XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { User } from '@xpo-ltl/sdk-common';
import { XpoAuthenticationService } from '@xpo/ngx-auth';
import { invoke as _invoke, isEmpty as _isEmpty, toLower as _toLower } from 'lodash';
import { interval, of } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  retryWhen,
  skipWhile,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { ConfigManagerProperties } from '../core/enums/config-manager-properties.enum';
import { FeaturesService } from '../core/services/features/features.service';
import { UserRoleService } from '../core/services/user-role/user-role.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  host: { class: 'app-Root' },
})
export class AppComponent implements OnDestroy {
  private unsubscriber = new Unsubscriber();

  build: string;
  region: string = '';

  constructor(
    private configManagerService: ConfigManagerService,
    public loggedInUserService: XpoLtlLoggedInUserService,
    private userRoleService: UserRoleService,
    private featureService: FeaturesService,
    private xpoAuthService: XpoAuthenticationService,
    private xpoLtlAuthService: XpoLtlAuthenticationService
  ) {
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    this.region = `${_toLower(configManagerService.getSetting<string>(ConfigManagerProperties.region))}`;

    this.xpoLtlAuthService.initAuthSetup$(this.region).subscribe(() => {});

    // when user is logged in finish initialization
    this.loggedInUserFunc();
    this.loggedInUserService.userLoggedIn$.subscribe(() => {
      console.log('loggedInUserService.userLoggedIn$ emitted event');
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  /**
   * Process when the user is logged in
   */
  private loggedInUserFunc() {
    // NEED TO WAIT UNTIL AUTH SERVICE HAS COMPLETED SSO LOGIN BEFORE ANY ADDITIONAL CALLS ARE MADE
    this.xpoAuthService
      .isLoggedIn$()
      .pipe(
        distinctUntilChanged(),
        switchMap((loggedIn: boolean) => {
          return !loggedIn
            ? of(undefined)
            : this.loggedInUserService
                .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
                .pipe(
                  retryWhen((errors) => errors.pipe(delay(1000), take(5))),
                  catchError((error) => {
                    console.log('loggedInUserFunc ERROR:', error);
                    return of(undefined);
                  })
                );
        }),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((user: User) => {
        this.userRoleService.setUser(user);

        if (user) {
          this.setDynatraceUserIdentity(user);
          // this.configureFeedback(user);
          this.featureService.loadFeatures$().subscribe();
        }
      });
  }

  private setDynatraceUserIdentity(user: User): void {
    const setUser = (): void =>
      _invoke(
        window['dtrum'],
        'identifyUser',
        !_isEmpty(user.emailAddress) ? user.emailAddress : !_isEmpty(user.userId) ? user.userId : 'PND_USER'
      );

    if ((window['dtrum'] || {}).identifyUser) {
      setUser();
    } else {
      let retryCount: number = 0;
      interval(1000)
        .pipe(
          tap(() => retryCount++),
          skipWhile(() => !(window['dtrum'] || {}).identifyUser && retryCount <= 60),
          take(1)
        )
        .subscribe(() => {
          setUser();
        });
    }
  }

  // CURRENTLY NOT BEING USED!
  // private configureFeedback(user: User): void {
  //   const jiraConfig = _get(window, 'ATL_JQ_PAGE_PROPS.fieldValues');
  //   if (jiraConfig) {
  //     // THESE VARIABLES ARE CONFIGURED IN THE ISSUE COLLECTOR FOR P&D PROJECT
  //     jiraConfig.environment = `${this.configManagerService.getSetting(
  //       ConfigManagerProperties.region
  //     )} - ${this.configManagerService.getSetting(ConfigManagerProperties.buildVersion)}`;
  //     jiraConfig.customfield_15875 = `${user.displayName} (${user.employeeId})`;
  //     jiraConfig.customfield_13435 = user.emailAddress;
  //   }
  // }
}
